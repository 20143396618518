import { Spin } from "antd";
import React from "react";

export default function Loader() {
  return (
    <div className="container">
      <div className="antd-loader">
        <Spin></Spin>
      </div>
    </div>
  );
}
