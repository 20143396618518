import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { handleLogin } from "../firebase/api";
import "./index.scss";
import { GoogleIcon } from "../common/Icons";
import { Button, Typography } from "antd";
import { ROUTES, TOKEN } from "../common/constants";

const { Text, Title } = Typography;

export default function Login() {
  const history = useHistory();

  const handleGoogleSignIn = async () => {
    handleLogin();
  };

  useEffect(() => {
    const accessToken = localStorage?.getItem(TOKEN);
    if (accessToken) history?.replace(ROUTES?.DASHBOARD);
  });

  return (
    <div className="login-form">
      <Title className="text-login" level={2}>
        Login
      </Title>
      <Button
        icon={<GoogleIcon />}
        variant="default"
        color="gray"
        className="btn-login"
        size="large"
        onClick={handleGoogleSignIn}
      >
        <Text strong>Sign In with Google</Text>
      </Button>
    </div>
  );
}
