import "./index.scss";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import {
  BubbleMenu,
  // Editor,
  EditorContent,
  // EditorProvider,
  // Extension,
  // FloatingMenu,
  // useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Button,  Skeleton, Space, Typography, message } from "antd";
import React, { useState } from "react";
import { MESSAGE } from "../../common/constants";
import { projectsRef } from "../../firebase/collection";
import { updateData } from "../../firebase/api";
// import Loader from "../../common/Loader";
// import { Plugin } from "@tiptap/pm/state";
import {
  CustomButton,
  TiptapButtonNode,
  customHeader,
  customParagraph,
} from "../tiptap/Extensions";

const { Text } = Typography;

// const MenuBar = () => {
//   const { editor } = useCurrentEditor();

//   if (!editor) {
//     return null;
//   }

//   return (
//     <div className="btn-wrapper">
//       <Button
//         onClick={() => editor.chain().focus().toggleBold().run()}
//         disabled={!editor.can().chain().focus().toggleBold().run()}
//         className={editor.isActive("bold") ? "is-active" : ""}
//       >
//         bold
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleItalic().run()}
//         disabled={!editor.can().chain().focus().toggleItalic().run()}
//         className={editor.isActive("italic") ? "is-active" : ""}
//       >
//         italic
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleStrike().run()}
//         disabled={!editor.can().chain().focus().toggleStrike().run()}
//         className={editor.isActive("strike") ? "is-active" : ""}
//       >
//         strike
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCode().run()}
//         disabled={!editor.can().chain().focus().toggleCode().run()}
//         className={editor.isActive("code") ? "is-active" : ""}
//       >
//         code
//       </Button>
//       <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
//         clear marks
//       </Button>
//       <Button onClick={() => editor.chain().focus().clearNodes().run()}>
//         clear nodes
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setParagraph().run()}
//         className={editor.isActive("paragraph") ? "is-active" : ""}
//       >
//         paragraph
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
//         className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
//       >
//         h1
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
//         className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
//       >
//         h2
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
//         className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
//       >
//         h3
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
//         className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
//       >
//         h4
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
//         className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
//       >
//         h5
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
//         className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
//       >
//         h6
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBulletList().run()}
//         className={editor.isActive("bulletList") ? "is-active" : ""}
//       >
//         bullet list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleOrderedList().run()}
//         className={editor.isActive("orderedList") ? "is-active" : ""}
//       >
//         ordered list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCodeBlock().run()}
//         className={editor.isActive("codeBlock") ? "is-active" : ""}
//       >
//         code block
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBlockquote().run()}
//         className={editor.isActive("blockquote") ? "is-active" : ""}
//       >
//         blockquote
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
//         horizontal rule
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHardBreak().run()}>
//         hard break
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().undo().run()}
//         disabled={!editor.can().chain().focus().undo().run()}
//       >
//         undo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().redo().run()}
//         disabled={!editor.can().chain().focus().redo().run()}
//       >
//         redo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setColor("#958DF1").run()}
//         className={
//           editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""
//         }
//       >
//         purple
//       </Button>
//     </div>
//   );
// };

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Underline.configure({
    HTMLAttributes: {
      class: "my-custom-class",
    },
  }),
  Placeholder.configure({
    placeholder: "Write something...",
    includeChildren: true,
    showOnlyCurrent: false,
    emptyNodeClass: "tiptap-placeholder",
  }),
  CustomButton,
  customParagraph,
  customHeader,
  TiptapButtonNode,
];

export default function PRD(props) {
  const {
    flow_content,
    prd_content,
    project_id,
    fetchTemplate,
    prdLoader,
    setKey,
    generateFlows,
  } = props;

  const [loading, setLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState(prd_content);

  const handleUpdate = async () => {
    setLoading(true);
    let isAdded = await updateData(projectsRef, "projects", project_id, {
      prd: currentContent,
    });

    if (isAdded) {
      message.success({ content: MESSAGE?.ADD });
      await fetchTemplate();
      setLoading(false);
    }
  };
  const handleEdit = () => {
    if (editor.isEditable) {
      //save
      handleUpdate();
    } else {
      editor?.setEditable(true);
      editor?.chain()?.focus();
    }
  };

  const editor = useEditor({
    extensions: extensions,
    editable: false,
    content: currentContent,
    onUpdate: (data) => {
      const { $from } = editor?.state?.selection;
      // const { state, prevState } = editor.view;
      const nodeName = $from?.node()?.type?.name;
      // const nodeAttrs = $from?.node()?.type?.attrs;
      // const nodeContent = $from?.node()?.textContent;

      // const { level } = $from?.node().attrs;
      let contentWithout = data?.editor
        ?.getHTML()
        .replaceAll(/<p/g, `<custom-paragraph`)
        .replaceAll(/p>/g, `custom-paragraph>`)
        .replaceAll(/<h1/g, `<custom-header`)
        .replaceAll(/h1>/g, `custom-header>`);

      // setCurrentContent(contentWithout);
      if (nodeName === "codeBlock" || nodeName === "doc") {
        setCurrentContent(currentContent);
        data?.editor.commands.setContent(currentContent);
      } else {
        setCurrentContent(contentWithout);
      }
      // // prevContent = data?.editor?.getHTML();
      // const { level } = $from?.node().attrs;

      // setCurrentContent(contentWithout);
      // setCurrentContent(contentWithout);
    },
  });

  const handleFlowsClick = async ({ formData, schema, uiSchema }) => {
    setLoading(true);
    let isAdded = await updateData(projectsRef, "projects", project_id, {
      prd: currentContent,
      status: "PROCESSING",
    });

    if (isAdded) {
      // message.success({ content: MESSAGE?.ADD });
      setLoading(false);
      setKey("4");
      await generateFlows(currentContent);
    }
  };

  if (prdLoader) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: 15,
        }}
      />
    );
  }

  return (
    <div>
      {editor && (
        <BubbleMenu
          className="bubble-menu"
          key="editor-bubble"
          tippyOptions={{ duration: 100 }}
          editor={editor}
        >
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            <Text strong>B</Text>
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            <Text italic>I</Text>
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive("underline") ? "is-active" : ""}
          >
            <Text underline>U</Text>
          </Button>
        </BubbleMenu>
      )}
      {/* <button onClick={handleAddBlock}>click</button> */}
      <div className="brd-wrapper">
        <div className="container">
          <div className={!editor?.isEditable ? "cursor-none" : ""}>
            <EditorContent editor={editor} />
          </div>
          <div className="footer-buttons">
            <Space>
              <Button onClick={handleEdit} loading={loading}>
                {editor?.isEditable ? "Save" : "Edit"}
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleFlowsClick}
              >
                {flow_content ? "Regenerate Flows" : "Generate Flows"}
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
}
