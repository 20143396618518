import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { Button } from "antd";

const TiptapButton = (props) => {
  return (
    <NodeViewWrapper className="add-btn-tiptap">
      <div contentEditable={false}>
        <Button
          size="small"
          onMouseDown={(e) => {
            e.preventDefault();

            // const { class: nodeClass } = props?.node?.attrs;
            const nodes = props?.node?.attrs?.class?.split(" ");
            nodes?.map((node) => {
              let nodeType = node?.split("*")[0];
              let nodeClass = node?.split("*")[1];
              if (nodeType === "paragraph") {
                const endPos = props.getPos() + props.node.nodeSize;
                props.editor.commands.focus(endPos);
                props.editor.commands.insertContent(
                  `<custom-paragraph class="${nodeClass}"></custom-paragraph>`
                );
              }
              if (nodeType === "heading") {
                const endPos = props.getPos() + props.node.nodeSize;
                props.editor.commands.focus(endPos);
                props.editor.commands.insertContent(
                  `<custom-header class="${nodeClass}"></custom-header>`
                );
              }
              return null;
            });

            // const addNodes = elementType?.split("-");
          }}
        >
          +
        </Button>
      </div>
      <NodeViewContent className="content" />
    </NodeViewWrapper>
  );
};

export default TiptapButton;
