import posthtml from "posthtml";
import beautify from "posthtml-beautify";

export const stripHTML = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "");
};

const convertNumberToString = (digit) => {
  switch (digit) {
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    default:
      break;
  }
};

const convertJsonToString = (json, counter, isBtn) => {
  let newString = "";
  let INDENT = convertNumberToString(counter);

  if (Array?.isArray(json)) {
    json?.map((arr) => {
      if (typeof arr === 'object') {
        Object?.entries(arr)?.map((data) => {
          if (data?.[0]) {
            newString += `<custom-header class="${INDENT}-h${counter}">${data?.[0]}</custom-header>`;
            if (isBtn) {
              newString += `<custom-button class="paragraph*${INDENT}-p"></custom-button>`;
            }
          }
          if (typeof data?.[1] !== "string") {
            newString += convertJsonToString(data?.[1], counter, isBtn);
          } else {
            newString += `<custom-paragraph class="${INDENT}-p">${data?.[1]}</custom-paragraph>`;
          }
          return data;
        });

      } else {
        newString += `<custom-paragraph class="two-p">${arr}</custom-paragraph>`;
      }
      return arr;
    });
    return newString;
  } else {
    Object?.entries(json)?.map((data) => {
      if (data?.[0]) {
        newString += `<custom-header class="${INDENT}-h${counter}">${data?.[0]}</custom-header>`;
        if (isBtn) {
          newString += `<custom-button class="paragraph*${INDENT}-p"></custom-button>`;
        }
      }
      if (typeof data?.[1] !== "string") {
        newString += convertJsonToString(data?.[1], counter++, isBtn);
      } else {
        newString += `<custom-paragraph class="${INDENT}-p">${data?.[1]}</custom-paragraph>`;
      }
      return data;
    });
    return newString;
  }
};

export const convertJsonToBrd = (json) => {
  if (json) {
    let brdString = "";

    Object?.entries(json)?.map((data) => {
      if (data?.[0]) {
        brdString += `<custom-header class="one-h1">${data?.[0]}</custom-header>`;
      }
      if (typeof data?.[1] !== "string") {
        brdString += convertJsonToString(data?.[1], 2, 0);
      } else {
        brdString += `<custom-paragraph class="one-p">${data?.[1]}</custom-paragraph>`;
      }
      return data;
    });

    return brdString;
  }
};

export const convertJsonToPrd = (json) => {
  if (json) {
    let brdString = "";
        Object?.entries(json)?.map((data) => {
      if (data?.[0]) {
        brdString += `<custom-header class="one-h1">${data?.[0]}</custom-header><custom-button class="paragraph*two-p heading*two-h2"></custom-button>`;
      }
      if (typeof data?.[1] !== "string") {
        brdString += convertJsonToString(data?.[1], 2, 1);
      } else {
        brdString += `<custom-paragraph class="one-p">${data?.[1]}</custom-paragraph>`;
      }
      return data;
    });

    return brdString;
  }
};

export const prettifyHTML = (htmlString) => {
  return posthtml()
    ?.use(beautify({ rules: { indent: 4 } }))
    ?.process(htmlString)
    ?.then((result) => {
      return result?.html;
    });
};
