import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

export default function Error404() {
  const history = useHistory();

  const handleRedirect = (path) => {
    history?.push(path);
  };

  return (
    <div className="container">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => handleRedirect("/dashboard")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
}
