import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./AppContext";
import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
ConfigProvider.config({
  theme: { primaryColor: "#000000" },
});

root.render(
  <AppContextProvider>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
