import "./index.scss";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import {
  // Editor,
  EditorContent,
  // EditorProvider,
  // Extension,
  // FloatingMenu,
  // useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Alert, Button, Drawer, Skeleton, Space, message } from "antd";
import React, { useMemo, useState } from "react";
import { MESSAGE } from "../../common/constants";
import { projectsRef } from "../../firebase/collection";
import { updateData } from "../../firebase/api";
// import Loader from "../../common/Loader";
// import { Plugin } from "@tiptap/pm/state";
import {
  RightOutlined,
  CodeOutlined,
  UpOutlined,
  BookOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import MermaidContainer from "../mermaid/MermaidContainer";
import { useEffect } from "react";
import { Typography } from "antd";

// const MenuBar = () => {
//   const { editor } = useCurrentEditor();

//   if (!editor) {
//     return null;
//   }

//   return (
//     <div className="btn-wrapper">
//       <Button
//         onClick={() => editor.chain().focus().toggleBold().run()}
//         disabled={!editor.can().chain().focus().toggleBold().run()}
//         className={editor.isActive("bold") ? "is-active" : ""}
//       >
//         bold
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleItalic().run()}
//         disabled={!editor.can().chain().focus().toggleItalic().run()}
//         className={editor.isActive("italic") ? "is-active" : ""}
//       >
//         italic
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleStrike().run()}
//         disabled={!editor.can().chain().focus().toggleStrike().run()}
//         className={editor.isActive("strike") ? "is-active" : ""}
//       >
//         strike
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCode().run()}
//         disabled={!editor.can().chain().focus().toggleCode().run()}
//         className={editor.isActive("code") ? "is-active" : ""}
//       >
//         code
//       </Button>
//       <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
//         clear marks
//       </Button>
//       <Button onClick={() => editor.chain().focus().clearNodes().run()}>
//         clear nodes
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setParagraph().run()}
//         className={editor.isActive("paragraph") ? "is-active" : ""}
//       >
//         paragraph
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
//         className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
//       >
//         h1
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
//         className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
//       >
//         h2
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
//         className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
//       >
//         h3
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
//         className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
//       >
//         h4
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
//         className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
//       >
//         h5
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
//         className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
//       >
//         h6
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBulletList().run()}
//         className={editor.isActive("bulletList") ? "is-active" : ""}
//       >
//         bullet list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleOrderedList().run()}
//         className={editor.isActive("orderedList") ? "is-active" : ""}
//       >
//         ordered list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCodeBlock().run()}
//         className={editor.isActive("codeBlock") ? "is-active" : ""}
//       >
//         code block
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBlockquote().run()}
//         className={editor.isActive("blockquote") ? "is-active" : ""}
//       >
//         blockquote
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
//         horizontal rule
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHardBreak().run()}>
//         hard break
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().undo().run()}
//         disabled={!editor.can().chain().focus().undo().run()}
//       >
//         undo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().redo().run()}
//         disabled={!editor.can().chain().focus().redo().run()}
//       >
//         redo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setColor("#958DF1").run()}
//         className={
//           editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""
//         }
//       >
//         purple
//       </Button>
//     </div>
//   );
// };

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

export default function UserFlows(props) {
  const {
    flow_content,
    project_id,
    fetchTemplate,
    flowLoader,
    setKey,
    generateWireFrames,
    wireframe_content,
    prd_content,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [toggle, setToggle] = useState(false);

  /*Drawer states*/
  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  /* Mermaid states*/
  const [codeText, setCodeText] = useState("");
  const [openBottom, setOpenBottom] = useState(false);

  /* Flow Editor states*/
  const [editorText, setEditorText] = useState("");

  /*Flow states */
  const [screensList, setScreensList] = useState([]);
  const [flowsList, setFlowsList] = useState([]);
  const [currentId, setCurrentId] = useState();

  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    setZoom(zoom + 0.5);
  };

  const handleZoomOut = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.5);
    }
  };

  const handleZoomReset = () => {
    setZoom(1);
  };

  const chartContainerStyle = {
    width: `${100 * zoom}%`, // Adjust the width based on the zoom level
    height: `${100 * zoom}%`, // Adjust the height based on the zoom level
    transform: `scale(${zoom})`,
    transformOrigin: "0 0",
    overflow: "hidden", // Hide overflowing content
  };

  const handleUpdate = async () => {
    setLoading(true);
    const flows = {
      flows: flowsList,
      screens: screensList,
    };
    let isAdded = await updateData(projectsRef, "projects", project_id, {
      flows: JSON?.stringify(flows),
    });

    if (isAdded) {
      message?.success({ content: MESSAGE?.ADD });
      await fetchTemplate();
      setLoading(false);
    }
  };

  const editor = useEditor({
    extensions: extensions,
    editable: true,
    content: codeText,
    onCreate: (data) => {
      setCodeText(data?.editor?.getText());
    },
    onUpdate: (data) => {
      if (flowsList?.length > 0) {
        flowsList[currentId].mermaid = data?.editor?.getHTML();
      }
      setCodeText(data?.editor?.getText());
    },
  });

  const editorOverview = useEditor({
    extensions: extensions,
    editable: true,
    content: editorText,
    onUpdate: (data) => {
      if (flowsList?.length > 0) {
        flowsList[currentId].text = data?.editor?.getHTML();
      }
    },
  });

  const handleWireFrameClick = async ({ formData, schema, uiSchema }) => {
    setLoading(true);
    const flows = {
      flows: flowsList,
      screens: screensList,
    };
    let isAdded = await updateData(projectsRef, "projects", project_id, {
      flows: JSON?.stringify(flows),
      status: "PROCESSING",
    });

    if (isAdded) {
      setLoading(false);
      setKey("5");
      await generateWireFrames(prd_content);
    }
  };

  const renderFlows = useMemo(() => {
    if (codeText) {
      return (
        <div className="chart-container" style={chartContainerStyle}>
          <MermaidContainer text={codeText} setError={(err) => setError(err)} />
        </div>
      );
    }
  }, [codeText, zoom]);

  useEffect(() => {
    if (flow_content) {
      const { Flows, flows, screens } = JSON?.parse(flow_content || []);
      setFlowsList(Flows || flows);
      setScreensList(screens);
    }
  }, [flow_content]);

  const handleScreenClick = (screen, id) => {
    editor?.commands?.setContent(screen?.mermaid);
    setCodeText(editor?.getText());
    editorOverview?.commands?.setContent(screen?.text);
    setCurrentId(id);
  };

  if (flowLoader) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: 15,
        }}
      />
    );
  }

  return (
    <div>
      <div className="user-flow-wrapper">
        <div id="mermaid-container"></div>
        <div className="container">
          <div className="user-flow-header">
            <Space direction="vertical" align="end">
              <Space>
                <Button
                  icon={<CodeOutlined />}
                  onClick={() => setOpenRight(!openRight)}
                >
                  Code
                </Button>
                <Button
                  icon={<BookOutlined />}
                  onClick={() => setOpenBottom(!openBottom)}
                >
                  Flows Description
                </Button>
              </Space>
              <Space>
                <Button
                  onClick={handleZoomOut}
                  icon={<MinusOutlined />}
                  size="small"
                  title="Zoom Out"
                ></Button>
                <Button
                  onClick={handleZoomIn}
                  icon={<PlusOutlined />}
                  size="small"
                  title="Zoom In"
                ></Button>
                <Button onClick={handleZoomReset} title="Reset" size="small">
                  Reset
                </Button>
              </Space>
            </Space>
          </div>
          <div className="middle-content">
            {flowsList && flowsList?.length > 1 && (
              <Button
                className="left-aligned-button"
                icon={<RightOutlined />}
                onClick={() => setOpenLeft(true)}
                size="small"
              ></Button>
            )}
            <div className="flow-figures">
              {/* <Mermaid text={mermaidText} /> */}
              {renderFlows}
            </div>
            {/* commenting for future use */}
            {/* <Button
              className="bottom-aligned-button"
              icon={<UpOutlined />}
              onClick={() => setOpenBottom(true)}
            >
              Onboarding Flow
            </Button> */}
          </div>
          <Drawer
            title="code"
            placement="right"
            mask={false}
            onClose={() => setOpenRight(false)}
            open={openRight}
            className="right-drawer"
          >
            <div className="code-editor">
              <EditorContent editor={editor} />
              {error && <Alert message={error} type="error" />}
            </div>
          </Drawer>
          <Drawer
            title="Flows Description"
            placement="bottom"
            height={400}
            mask={false}
            onClose={() => setOpenBottom(false)}
            open={openBottom}
            className="bottom-drawer"
          >
            <EditorContent editor={editorOverview} />
          </Drawer>
          <Drawer
            title="Flows"
            placement="left"
            mask={false}
            width={300}
            onClose={() => setOpenLeft(false)}
            open={openLeft}
            className="left-drawer"
          >
            <div className="screen-list-wrapper">
              {flowsList && flowsList?.length > 0
                ? flowsList?.map((screen, idx) => {
                    if (idx === 0 && !toggle) {
                      setToggle(true);
                      handleScreenClick(screen, idx);
                    }
                    return (
                      <div className="list-wrapper">
                        {screen?.title && (
                          <Typography.Title
                            level={5}
                            className={`text-header ${
                              currentId === idx ? "active" : ""
                            }`}
                            onClick={() => handleScreenClick(screen, idx)}
                          >
                            {screen?.title || "-"}
                          </Typography.Title>
                        )}
                        <ul>
                          {screen?.screen && screen?.screen?.length > 0 ? (
                            screen?.screen?.map((list) => (
                              <li>{list?.ScreenTitle}</li>
                            ))
                          ) : (
                            <li>-</li>
                          )}
                        </ul>
                      </div>
                    );
                  })
                : null}
            </div>
          </Drawer>
          <div className="brd-wrapper">
            <div className="container">
              <div className="footer-buttons">
                <Space>
                  <Button onClick={handleUpdate} loading={loading}>
                    {editor?.isEditable ? "Save" : "Edit"}
                  </Button>
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleWireFrameClick}
                  >
                    {wireframe_content
                      ? "Regenerate Wireframes"
                      : "Generate Wireframes"}
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
