export const ROUTES = {
  LOGIN: "/login",
  USER: "/user",
  TEMPLATES: "/templates",
  TEMPLATE_DETAILS: "/template/:id",
  BRD: "/brd",
  PRD: "/prd",
  DASHBOARD: "/dashboard",
  PROJECTS: "/projects/:id",
  HOME: "/",
  NOT_FOUND: "/404",
};

export const MESSAGE = {
  ADD: "Details saved successfully",
  EMPTY_TEMPLATE: "Template does not exist!",
  BRD_GENERATED: "BRD Generated!",
  PRD_GENERATED: "PRD Generated!",
  FLOWS_GENERATED: "Flows Generated!",
  WIREFRAMES_GENERATED: "Wireframes Generated!",
  NEED_ACCESS: "You need permission to view this Dashboard.",
  PERMISSION_REQUEST: "Permission requested!",
  REQUIRED: "This field is required",
};

/* Authentication */
export const TOKEN = "TOKEN";
