import { Redirect, Route } from "react-router-dom";
import Header from "../common/Header";
import { ROUTES, TOKEN } from "../common/constants";

const AuthWrapper = ({ component, ...rest }) => {
  const isAuth = localStorage?.getItem(TOKEN);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <>
            <Header />
            {component}
            {/* <Footer /> */}
          </>
        ) : (
          <Redirect to={ROUTES?.LOGIN} />
        )
      }
    />
  );
};

export default AuthWrapper;
