import { Button, Result, message } from "antd";
import React, { useContext, useState } from "react";
import { MESSAGE } from "./constants";
import { fetchDataWithWhere, handleError } from "../firebase/api";
import { AppContext } from "../AppContext";
import { doc, setDoc, where } from "firebase/firestore";
import { usersRef } from "../firebase/collection";
import "./index.scss";

export default function RequestVerification() {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const handleAccess = async () => {
    setLoading(true);
    const uid = state?.userData?.uid;
    const payload = state?.userData;
    try {
      const fetchData = await fetchDataWithWhere(
        usersRef,
        null,
        where("uid", "==", uid)
      );
      const id = fetchData?.[0]?.id;
      await setDoc(doc(usersRef, id), { ...payload, access: true });
      dispatch({ type: "SET_USER_DATA", data: { ...payload, access: true } });
      message?.success(MESSAGE?.PERMISSION_REQUEST);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
    // if (user?.length === 0) {
    //     await addData(usersRef, {
    //       displayName,
    //       email,
    //       uid,
    //       photoURL,
    //       isVerified: false,
    //       access: false,
    //       createdAt: new Date().toISOString(),
    //     });
    //   }
    // let isAdded = await updateData(usersRef, "users", project_id, {
    //     brd: currentContent,
    //   });
    //   if (isAdded) {
    //     message.success({ content: MESSAGE?.ADD });
    //     await fetchTemplate();
    //     setLoading(false);
    //   }
  };

  return (
    <div className="container">
      <Result
        status="warning"
        title={MESSAGE?.NEED_ACCESS}
        extra={
          <Button
            type="primary"
            key="console"
            onClick={handleAccess}
            loading={loading || state?.userData?.access}
          >
            {state?.userData?.access ? "Requested" : "Request Permission"}
          </Button>
        }
      />
    </div>
  );
}
