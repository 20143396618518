import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Component from "../tiptap/Component";
import TiptapButton from "./TiptapButton";

export const FixNode = Node.create({
  name: "fixComponent",

  // group: "block",

  // atom: true,
  // selectable: false,

  // content: "inline*",
  group: "block",
  // inline: true,
  selectable: true, // Make the node unselectable
  // atom: true,
  addAttributes() {
    return {
      level: {
        default: 0,
      },
      content: {
        default: 0,
      },
    };
  },
  onDestroy() {
    return ReactNodeViewRenderer(Component);
  },
  draggable: false,
  parseHTML() {
    return [
      {
        tag: "fix-component",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["fix-component", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
export const CustomButton = Node.create({
  name: "customButton",
  // content: 'block',
  group: "block",
  selectable: false,
  draggable: false,
  atom: false,
  addAttributes() {
    return {
      type: {
        default: 0,
      },
      // content: {
      //   default: 0,
      // },
    };
  },
  onDestroy() {
    return ReactNodeViewRenderer(Component);
  },
  parseHTML() {
    return [
      {
        tag: "custom-button",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["custom-button", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

export const customParagraph = Node.create({
  name: "customParagraph",
  content: "text*",
  group: "block",
  selectable: true,
  draggable: false,
  atom: false,

  attrs: {
    class: "custom-paragraph",
  },
  // parseDOM: [{ tag: 'p.custom-paragraph' }],
  // toDOM(node) {
  //   return ['p', { class: node.attrs.class }, 0];
  // },
  onDestroy() {
    return ReactNodeViewRenderer(Component);
  },
  addAttributes() {
    return {
      class: {
        default: 1,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "custom-paragraph",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["p", { class: `${HTMLAttributes?.class}` }, 0];
  },
});

export const customHeader = Node.create({
  name: "customHeader",
  content: "text*",
  group: "block",
  selectable: true,
  draggable: false,
  atom: false,
  attrs: {
    class: "custom-header",
  },
  // parseDOM: [{ tag: 'p.custom-paragraph' }],
  // toDOM(node) {
  //   return ['p', { class: node.attrs.class }, 0];
  // },
  onDestroy() {
    return ReactNodeViewRenderer(Component);
  },
  addAttributes() {
    return {
      class: {
        default: 1,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "custom-header",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["h1", { class: `${HTMLAttributes?.class}` }, 0];
  },
});

export const TiptapButtonNode = Node.create({
  name: "paragraph",
  selectable: true,
  defaultOptions: {
    HTMLAttributes: {},
  },
  group: "block",
  // content: "inline*",
  addAttributes() {
    return {
      class: {
        default: 1,
      },
    };
  },

  parseHTML: () => {
    return [{ tag: "custom-button" }];
  },

  // renderHTML: ({ HTMLAttributes }) => {
  //   return ["div", mergeAttributes(HTMLAttributes, { class: "block" }), 0];
  // },
  renderHTML({ HTMLAttributes }) {
    return ["custom-button", mergeAttributes(HTMLAttributes)];
  },

  addNodeView: () => {
    return ReactNodeViewRenderer(TiptapButton);
  },

  // addCommands() {
  //   return {
  //     setParagraph:
  //       () =>
  //       ({ commands }) => {
  //         return commands.toggleNode("paragraph", "paragraph");
  //       },
  //   };
  // },

  // addKeyboardShortcuts() {
  //   return {
  //     "Mod-Alt-0": () => this.editor.commands.setParagraph(),
  //   };
  // },
});
