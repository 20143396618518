import React, { useEffect, useState } from "react";
import { fetchSingleData } from "../../firebase/api";
import { useParams } from "react-router-dom";
import RenderForm from "./RenderForm";
import { Empty } from "antd";
import Loader from "../../common/Loader";
import { MESSAGE } from "../../common/constants";
import Back from "../../common/Back";

export default function TemplateDetails() {
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchTemplate = async () => {
      const data = await fetchSingleData("templates", id);
      setFormData(data);
      setLoading(false);
    };
    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="container">
      <Back path="/templates" />
      {formData && formData?.is_active === true ? (
        <RenderForm
          formSchema={formData?.json_schema}
          uiSchema={formData?.ui_schema}
          template_id={formData?.id}
          formData={formData?.form_data}
        />
      ) : (
        <Empty description={MESSAGE?.EMPTY_TEMPLATE} />
      )}
    </div>
  );
}
