import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleData, updateData } from "../../firebase/api";
import { Button, Modal, Space, Tabs, Typography, message } from "antd";
import RenderForm from "../template/RenderForm";
import Loader from "../../common/Loader";
import BRD from "../../modules/brd/BRD";
import { AppContext } from "../../AppContext";
import Back from "../../common/Back";
import { ROUTES } from "../../common/constants";
import axios from "axios";
import { projectsRef } from "../../firebase/collection";
import PRD from "../brd/PRD";
import UserFlows from "../brd/UserFlows";
import WireFrames from "../brd/WireFrames";
import {
  convertJsonToBrd,
  stripHTML,
  convertJsonToPrd,
} from "../../common/functions";
import { useHistory } from "react-router-dom";

export default function Projects() {
  const { id } = useParams();
  const [projectData, setProjectData] = useState();
  const [loading, setLoading] = useState(true);
  const { dispatch, state } = useContext(AppContext);
  const [currentActiveKey, setCurrentActiveKey] = useState("1");
  const [brdLoader, setBrdLoader] = useState(false);
  const [prdLoader, setPrdLoader] = useState(false);
  const [flowLoader, setFlowLoader] = useState(false);
  const [wireFramesLoader, setWireFramesLoader] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const history = useHistory();

  const fetchTemplate = async () => {
    setLoading(true);
    const data = await fetchSingleData("projects", id);
    if (data?.is_active) {
      setProjectData(data);
    } else {
      history?.push(ROUTES?.NOT_FOUND);
    }
    if (data?.brd) {
      dispatch({ type: "TOGGLE_BRD_BUTTON", data: { isBRD: true } });
    }
    if (data?.prd) {
      dispatch({ type: "TOGGLE_PRD_BUTTON", data: { isPRD: true } });
    }
    if (data?.flows) {
      dispatch({ type: "TOGGLE_FLOW_BUTTON", data: { isFlows: true } });
    }
    if (data?.wireframes) {
      dispatch({ type: "TOGGLE_WIREFRAME_BUTTON", data: { isFrames: true } });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const generateBRD = async (formData) => {
    setBrdLoader(true);
    message?.loading({ content: "Generating BRD in progress..", duration: 0 });
    axios
      ?.post(
        projectData?.brd_url,
        {
          form_responses: JSON?.stringify(formData),
          token: process.env.REACT_APP_API_TOKEN,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
            "Content-Type": "application/json",
          },
        }
      )
      ?.then(async (response) => {
        if (response?.data?.data) {
          await handleUpdate(response?.data?.data);
          await fetchTemplate();
        }
        setBrdLoader(false);
        message?.destroy();
      })
      ?.catch((e) => {
        setBrdLoader(false);
        message?.destroy();
        message?.error({ content: e?.message });
        setCurrentActiveKey("1");
      });
  };

  const generatePRD = async (brd) => {
    setPrdLoader(true);
    message?.loading({ content: "Generating PRD in progress..", duration: 0 });
    axios
      ?.post(
        projectData?.prd_url,
        {
          brd: stripHTML(brd),
          form_data: projectData?.form_data,
          token: process.env.REACT_APP_API_TOKEN,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
            "Content-Type": "application/json",
          },
        }
      )
      ?.then(async (response) => {
        if (response?.data?.data) {
          await handlePRDUpdate(response?.data?.data);
          await fetchTemplate();
        }
        setPrdLoader(false);
        message?.destroy();
      })
      ?.catch((e) => {
        setPrdLoader(false);
        message?.destroy();
        message?.error({ content: e?.message });
        setCurrentActiveKey("2");
      });
  };

  const generateFlows = async (prd) => {
    setFlowLoader(true);
    message?.loading({
      content: "Generating User flows in progress..",
      duration: 0,
    });
    axios
      .post(
        projectData?.flow_url,
        {
          payload: stripHTML(prd),
          brd: stripHTML(projectData?.brd),
          form_data: projectData?.form_data,
          token: process.env.REACT_APP_API_TOKEN,
          prd: stripHTML(prd),
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.data) {
          await handleFlowsUpdate(response?.data?.data);
          await fetchTemplate();
        }
        setFlowLoader(false);
        message?.destroy();
      })
      .catch((e) => {
        setFlowLoader(false);
        message?.destroy();
        message?.error({ content: e?.message });
        setCurrentActiveKey("3");
      });
  };

  const generateWireFrames = async (prd) => {
    setWireFramesLoader(true);
    setIsModal(false);
    message?.loading({
      content: "Generating Wireframes in progress..",
      duration: 0,
    });
    axios
      .post(
        projectData?.wireframe_url,
        {
          user_flow: projectData?.flows,
          brd: stripHTML(projectData?.brd),
          prd: stripHTML(projectData?.prd),
          form_data: projectData?.form_data,
          token: process.env.REACT_APP_API_TOKEN,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.data) {
          await handleWireFramesUpdate(response?.data?.data);
          await fetchTemplate();
        } else {
          setIsModal(true);
        }
        setWireFramesLoader(false);
        message?.destroy();
      })
      .catch((e) => {
        setWireFramesLoader(false);
        message?.destroy();
        message?.error({ content: e?.message });
        setCurrentActiveKey("4");
      });
  };

  const FormWrapper = () => {
    return (
      <div>
        <RenderForm
          brd_content={projectData?.brd}
          formSchema={projectData?.json_schema}
          uiSchema={projectData?.ui_schema}
          formData={projectData?.form_data}
          project_id={id}
          fetchTemplate={fetchTemplate}
          setKey={(current) => setCurrentActiveKey(current)}
          generateBRD={generateBRD}
          is_dynamic={projectData?.is_dynamic}
          question_url={projectData?.question_url}
          question_generated={projectData?.question_generated}
        />
      </div>
    );
  };

  const BRDWrapper = () => {
    return (
      <BRD
        brd_content={projectData?.brd}
        prd_content={projectData?.prd}
        project_id={id}
        formData={projectData?.form_data}
        fetchTemplate={fetchTemplate}
        setKey={(current) => setCurrentActiveKey(current)}
        // setBrdLoader={(toggle) => setBrdLoader(toggle)}
        brdLoader={brdLoader}
        generatePRD={generatePRD}
      />
      // <HTMLElement/>
    );
  };

  const PRDWrapper = () => {
    return (
      <PRD
        brd_content={projectData?.brd}
        prd_content={projectData?.prd}
        flow_content={projectData?.flows}
        project_id={id}
        formData={projectData?.form_data}
        fetchTemplate={fetchTemplate}
        setKey={(current) => setCurrentActiveKey(current)}
        // setBrdLoader={(toggle) => setBrdLoader(toggle)}
        prdLoader={prdLoader}
        generateFlows={generateFlows}
      />
      // <HTMLElement/>
    );
  };

  const FlowsWrapper = () => {
    return (
      <UserFlows
        brd_content={projectData?.brd}
        prd_content={projectData?.prd}
        flow_content={projectData?.flows}
        wireframe_content={projectData?.wireframes}
        project_id={id}
        formData={projectData?.form_data}
        fetchTemplate={fetchTemplate}
        setKey={(current) => setCurrentActiveKey(current)}
        // setBrdLoader={(toggle) => setBrdLoader(toggle)}
        flowLoader={flowLoader}
        generateWireFrames={generateWireFrames}
      />
      // <HTMLElement/>
    );
  };

  const WireFramesWrapper = () => {
    return (
      <WireFrames
        brd_content={projectData?.brd}
        prd_content={projectData?.prd}
        flow_content={projectData?.flows}
        wireframe_content={projectData?.wireframes}
        project_id={id}
        formData={projectData?.form_data}
        fetchTemplate={fetchTemplate}
        setKey={(current) => setCurrentActiveKey(current)}
        // setBrdLoader={(toggle) => setBrdLoader(toggle)}
        wireFramesLoader={wireFramesLoader}
        generateWireFrames={generateWireFrames}
      />
      // <HTMLElement/>
    );
  };

  const handleUpdate = async (brd) => {
    setLoading(true);
    await updateData(projectsRef, "projects", id, {
      brd: convertJsonToBrd(brd),
      status: "PROCESSED",
    });
  };

  const handlePRDUpdate = async (prd) => {
    setLoading(true);
    await updateData(projectsRef, "projects", id, {
      prd: convertJsonToPrd(prd),
      status: "PROCESSED",
    });
  };

  const handleFlowsUpdate = async (flows) => {
    setLoading(true);
    await updateData(projectsRef, "projects", id, {
      flows: JSON?.stringify(flows),
      status: "PROCESSED",
    });
  };

  const handleWireFramesUpdate = async (wireframes) => {
    setLoading(true);
    await updateData(projectsRef, "projects", id, {
      wireframes: JSON?.stringify(wireframes),
      status: "PROCESSED",
    });
  };

  return (
    <div className="project-details-wrapper">
      <div className="container">
        <Space className="header-wrap">
          <Back path={ROUTES?.DASHBOARD} />
          <Typography.Title level={3} className="title-project">
            {projectData?.project_name}
          </Typography.Title>
        </Space>
        <Tabs
          centered
          defaultActiveKey="1"
          activeKey={currentActiveKey}
          className="action-tabs"
          onChange={(key) => setCurrentActiveKey(key)}
          items={[
            {
              label: "Form",
              key: "1",
              children: !loading ? <FormWrapper /> : <Loader />,
            },
            {
              label: "BRD",
              key: "2",
              children: !loading ? <BRDWrapper /> : <Loader />,
              disabled: !state?.isBRD,
            },
            {
              label: "PRD",
              key: "3",
              children: !loading ? <PRDWrapper /> : <Loader />,
              disabled: !state?.isPRD,
            },
            {
              label: "User Flows",
              key: "4",
              children: !loading ? <FlowsWrapper /> : <Loader />,
              disabled: !state?.isFlows,
            },
            {
              label: "Wireframes",
              key: "5",
              children: !loading ? <WireFramesWrapper /> : <Loader />,
              disabled: !state?.isFrames,
            },
          ]}
        />
        <Modal
          title={<Typography.Text type="danger">Warning!</Typography.Text>}
          open={isModal}
          footer={null}
          onCancel={() => setIsModal(false)}
          className="warning-modal"
        >
          <Typography.Paragraph>
            We apologize for the inconvenience. The tool encountered an issue
            and couldn't generate the desired output at this time.
          </Typography.Paragraph>
          <Typography.Paragraph>
            In the meantime, you can try again.
          </Typography.Paragraph>
          <Button
            type="primary"
            onClick={generateWireFrames}
            className="warning-footer-btn"
          >
            Regenerate
          </Button>
        </Modal>
      </div>
    </div>
  );
}
