import { useContext, useEffect, useState } from "react";
import { handleError } from "../firebase/api";
import { usersRef } from "../firebase/collection";
import { onSnapshot, query, where } from "firebase/firestore";
import { AppContext } from "../AppContext";
import Loader from "../common/Loader";
import RequestVerification from "../common/RequestVerification";

const VerifyUser = ({ children }) => {
  const { state, dispatch } = useContext(AppContext);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (uid) => {
    try {
      await fetchRealTimeData(usersRef, null, where("uid", "==", uid));
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const fetchRealTimeData = async (table, id, condition) => {
    try {
      const searchQuery = query(table, condition);
      onSnapshot(searchQuery, (snapshot) => {
        snapshot?.docChanges()?.forEach((change) => {
          if (change?.type === "added" || change?.type === "modified") {
            const data = change?.doc?.data();
            if (data) {
              const { isVerified } = data;
              dispatch({ type: "SET_USER_DATA", data: data });
              setIsVerified(isVerified);
              setLoading(false);
            }
          }
        });
      });
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console?.error(error);
    }
  };
  useEffect(() => {
    if (state?.user?.uid) {
      fetchUserData(state?.user?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user]);

  if (loading) return <Loader />;
  if (isVerified) return children;
  if (!isVerified) return <RequestVerification />;
};

export default VerifyUser;
