import "./index.scss";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {
  // Editor,
  EditorContent,
  // EditorProvider,
  // Extension,
  // FloatingMenu,
  // useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Alert, Button, Drawer, Input, Skeleton, Space, message } from "antd";
import React, { useMemo, useState } from "react";
import { MESSAGE } from "../../common/constants";
import { projectsRef } from "../../firebase/collection";
import { updateData } from "../../firebase/api";
// import Loader from "../../common/Loader";
// import { Plugin } from "@tiptap/pm/state";
import {
  RightOutlined,
  CodeOutlined,
  UpOutlined,
  BookOutlined,
} from "@ant-design/icons";
import MermaidContainer from "../mermaid/MermaidContainer";
import WireframesWrapper from "../wireframes/WireframesWrapper";
import { useEffect } from "react";
import { Typography } from "antd";
import { prettifyHTML } from "../../common/functions";

// const MenuBar = () => {
//   const { editor } = useCurrentEditor();

//   if (!editor) {
//     return null;
//   }

//   return (
//     <div className="btn-wrapper">
//       <Button
//         onClick={() => editor.chain().focus().toggleBold().run()}
//         disabled={!editor.can().chain().focus().toggleBold().run()}
//         className={editor.isActive("bold") ? "is-active" : ""}
//       >
//         bold
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleItalic().run()}
//         disabled={!editor.can().chain().focus().toggleItalic().run()}
//         className={editor.isActive("italic") ? "is-active" : ""}
//       >
//         italic
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleStrike().run()}
//         disabled={!editor.can().chain().focus().toggleStrike().run()}
//         className={editor.isActive("strike") ? "is-active" : ""}
//       >
//         strike
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCode().run()}
//         disabled={!editor.can().chain().focus().toggleCode().run()}
//         className={editor.isActive("code") ? "is-active" : ""}
//       >
//         code
//       </Button>
//       <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
//         clear marks
//       </Button>
//       <Button onClick={() => editor.chain().focus().clearNodes().run()}>
//         clear nodes
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setParagraph().run()}
//         className={editor.isActive("paragraph") ? "is-active" : ""}
//       >
//         paragraph
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
//         className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
//       >
//         h1
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
//         className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
//       >
//         h2
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
//         className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
//       >
//         h3
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
//         className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
//       >
//         h4
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
//         className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
//       >
//         h5
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
//         className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
//       >
//         h6
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBulletList().run()}
//         className={editor.isActive("bulletList") ? "is-active" : ""}
//       >
//         bullet list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleOrderedList().run()}
//         className={editor.isActive("orderedList") ? "is-active" : ""}
//       >
//         ordered list
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleCodeBlock().run()}
//         className={editor.isActive("codeBlock") ? "is-active" : ""}
//       >
//         code block
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().toggleBlockquote().run()}
//         className={editor.isActive("blockquote") ? "is-active" : ""}
//       >
//         blockquote
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
//         horizontal rule
//       </Button>
//       <Button onClick={() => editor.chain().focus().setHardBreak().run()}>
//         hard break
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().undo().run()}
//         disabled={!editor.can().chain().focus().undo().run()}
//       >
//         undo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().redo().run()}
//         disabled={!editor.can().chain().focus().redo().run()}
//       >
//         redo
//       </Button>
//       <Button
//         onClick={() => editor.chain().focus().setColor("#958DF1").run()}
//         className={
//           editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""
//         }
//       >
//         purple
//       </Button>
//     </div>
//   );
// };

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

export default function WireFrames(props) {
  const {
    wireframe_content,
    project_id,
    fetchTemplate,
    flowLoader,
    prd_content,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [toggle, setToggle] = useState(false);

  /*Drawer states*/
  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  /* Mermaid states*/
  const [codeText, setCodeText] = useState("");
  const [openBottom, setOpenBottom] = useState(false);

  /* Flow Editor states*/
  const [editorText, setEditorText] = useState("");

  /*Wireframes states */
  const [screensList, setScreensList] = useState([]);
  const [wireframesList, setWireframesList] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [subCurrentId, setSubCurrentId] = useState();
  const [title, setTitle] = useState("");
  const [counter, setCounter] = useState(0);

  const handleUpdate = async () => {
    setLoading(true);
    const wireframes = {
      wireframes: wireframesList,
    };
    let isAdded = await updateData(projectsRef, "projects", project_id, {
      wireframes: JSON?.stringify(wireframes),
    });

    if (isAdded) {
      message.success({ content: MESSAGE?.ADD });
      await fetchTemplate();
      setLoading(false);
    }
  };

  const editorOverview = useEditor({
    extensions: extensions,
    editable: false,
    content: editorText,
    onUpdate: (data) => {
      if (wireframesList?.length > 0) {
        wireframesList[currentId].text = data?.editor?.getHTML();
      }
    },
  });

  useEffect(() => {
    if (wireframe_content) {
      const { wireframes } = JSON?.parse(wireframe_content || []);
      setWireframesList(wireframes);
    }
  }, [wireframe_content]);

  const handleScreenClick = async (list, idx) => {
    const formattedText = await prettifyHTML(list?.[1]); 
    setCodeText(formattedText);
    setTitle(list?.[0]);
    setCurrentId(idx);
    setSubCurrentId(list?.[0]);
  };

  const handleCodeUpdate = async (codeText) => {
    const data = counter === 0 ? await prettifyHTML(codeText) : codeText;
    setCodeText(data);
    setCounter(1);
    if (wireframesList?.length > 0) {
      wireframesList?.map((list, idx) => {
        if (currentId === idx) {
          if (list?.WireframeScreenName) {
            list.WireframeScreenName[subCurrentId] = data;
          }
        }
        return list;
      });
    }
  };

  useEffect(() => {
    if (codeText) {
      handleCodeUpdate(codeText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeText]);

  if (flowLoader) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: 15,
        }}
      />
    );
  }

  return (
    <div>
      <div className="user-flow-wrapper">
        <div id="mermaid-container"></div>
        <div className="container">
          <div className="user-flow-header">
            <Space>
              <Button
                icon={<CodeOutlined />}
                onClick={() => setOpenRight(!openRight)}
              >
                Code
              </Button>
              {/* commenting for future user */}
              {/* <Button
                icon={<BookOutlined />}
                onClick={() => setOpenBottom(!openBottom)}
              >
                Onboarding Flows
              </Button> */}
            </Space>
          </div>
          <div className="middle-content">
            <Button
              className="left-aligned-button"
              icon={<RightOutlined />}
              onClick={() => setOpenLeft(true)}
              size="small"
            ></Button>
            <div className="flow-figures">
              {codeText && (
                <WireframesWrapper
                  htmlString={codeText}
                  title={title}
                ></WireframesWrapper>
              )}
            </div>
            {/* commenting for future use */}
            {/* <Button
              className="bottom-aligned-button"
              icon={<UpOutlined />}
              onClick={() => setOpenBottom(true)}
            >
              Onboarding Flow
            </Button> */}
          </div>
          <Drawer
            title="code"
            placement="right"
            mask={false}
            width={500}
            onClose={() => setOpenRight(false)}
            open={openRight}
            className="right-drawer padding-0"
          >
            <div className="code-editor">
              <CodeEditor
                value={codeText}
                className="code-editor-custom-wrapper"
                language="javascript"
                placeholder="Please enter HTML code."
                onChange={(event) => setCodeText(event?.target?.value)}
                padding={15}
              />
            </div>
          </Drawer>
          <Drawer
            title="Onboarding Flow"
            placement="bottom"
            height={400}
            mask={false}
            onClose={() => setOpenBottom(false)}
            open={openBottom}
            className="bottom-drawer"
          >
            <EditorContent editor={editorOverview} />
          </Drawer>
          <Drawer
            title="Screens"
            placement="left"
            mask={false}
            width={300}
            onClose={() => setOpenLeft(false)}
            open={openLeft}
            className="left-drawer"
          >
            <div className="screen-list-wrapper">
              {wireframesList && wireframesList?.length > 0
                ? wireframesList?.map((screen, idx) => {
                    if (idx === 0 && !toggle) {
                      setToggle(true);
                      handleScreenClick(
                        Object?.entries(screen?.WireframeScreenName)?.map(
                          (d) => d
                        )?.[0],
                        0
                      );
                    }
                    return (
                      <div className="list-wrapper">
                        <Typography.Title level={5}>
                          {screen?.WireframeTitle || "-"}
                        </Typography.Title>
                        <ul>
                          {screen?.WireframeScreenName &&
                          Object?.entries(screen?.WireframeScreenName)?.length >
                            0 ? (
                            Object?.entries(screen?.WireframeScreenName)?.map(
                              (list) => (
                                <li
                                  className={`text-header ${
                                    currentId === idx &&
                                    subCurrentId === list?.[0]
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleScreenClick(list, idx)}
                                >
                                  {list?.[0]}
                                </li>
                              )
                            )
                          ) : (
                            <li></li>
                          )}
                        </ul>
                      </div>
                    );
                  })
                : null}
            </div>
          </Drawer>
          <div className="brd-wrapper">
            <div className="container">
              <div className="footer-buttons">
                <Space>
                  <Button
                    onClick={handleUpdate}
                    type="primary"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
