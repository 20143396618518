import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

export default function Back({ path }) {
    const history = useHistory();
  const handleBack = () => {
    history?.push(path);
  };
  return (
    <div className="back-wrapper">
      <Button
        icon={<ArrowLeftOutlined />}
        shape="circle"
        onClick={handleBack}
      ></Button>
    </div>
  );
}
