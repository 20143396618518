import React, { useEffect, useRef } from "react";
import mermaid from "mermaid";

export default function Mermaid({ text, setError }) {
  const ref = useRef(null);

  useEffect(() => {
    mermaid.mermaidAPI.initialize({
      startOnLoad: true,
      securityLevel: "loose",
      theme: "default",
      logLevel: 5,
      
    });
  });

  useEffect(() => {
    if (ref.current && text !== "") {
      setError("");
      try {
        mermaid?.mermaidAPI?.render("preview", text, (result) => {
          ref.current.innerHTML = result;
        });
      } catch (error) {
        ref.current.innerHTML = null;
        setError(error?.message);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return <div key="preview" ref={ref} />;
}
