import React from "react";
import "./index.scss";
import { Typography } from "antd";
//commenting for future use
// import JsxParser from "react-jsx-parser";
// import * as CarbonComponents from "@carbon/react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionSkeleton,
//   ActionableNotification,
//   AspectRatio,
//   Breadcrumb,
//   BreadcrumbItem,
//   BreadcrumbSkeleton,
//   Button,
//   ButtonKinds,
//   ButtonSet,
//   ButtonSizes,
//   ButtonSkeleton,
//   ButtonTooltipAlignments,
//   ButtonTooltipPositions,
//   Checkbox,
//   CheckboxGroup,
//   CheckboxSkeleton,
//   ClassPrefix,
//   ClickableTile,
//   CodeSnippet,
//   CodeSnippetSkeleton,
//   Column,
//   ColumnHang,
//   ComboBox,
//   ComboButton,
//   ComposedModal,
//   ContainedList,
//   ContainedListItem,
//   Content,
//   ContentSwitcher,
//   ControlledPasswordInput,
//   Copy,
//   CopyButton,
//   DangerButton,
//   DataTable,
//   DataTableSkeleton,
//   DatePicker,
//   DatePickerInput,
//   DatePickerSkeleton,
//   DefinitionTooltip,
//   Dropdown,
//   DropdownSkeleton,
//   ErrorBoundary,
//   ErrorBoundaryContext,
//   ExpandableSearch,
//   ExpandableTile,
//   FileUploader,
//   FileUploaderButton,
//   FileUploaderDropContainer,
//   FileUploaderItem,
//   FileUploaderSkeleton,
//   Filename,
//   FilterableMultiSelect,
//   FlexGrid,
//   FluidForm,
//   Form,
//   FormContext,
//   FormGroup,
//   FormItem,
//   FormLabel,
//   GlobalTheme,
//   Grid,
//   HStack,
//   Header,
//   HeaderContainer,
//   HeaderGlobalAction,
//   HeaderGlobalBar,
//   HeaderMenu,
//   HeaderMenuButton,
//   HeaderMenuItem,
//   HeaderName,
//   HeaderNavigation,
//   HeaderPanel,
//   HeaderSideNavItems,
//   Heading,
//   IconButton,
//   IconSkeleton,
//   IconSwitch,
//   IconTab,
//   IdPrefix,
//   InlineLoading,
//   InlineNotification,
//   Layer,
//   Link,
//   ListItem,
//   Loading,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuItemDivider,
//   MenuItemGroup,
//   MenuItemRadioGroup,
//   MenuItemSelectable,
//   Modal,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   ModalWrapper,
//   MultiSelect,
//   NotificationActionButton,
//   NotificationButton,
//   NumberInput,
//   NumberInputSkeleton,
//   OrderedList,
//   OverflowMenu,
//   OverflowMenuItem,
//   Pagination,
//   PaginationNav,
//   PaginationSkeleton,
//   PasswordInput,
//   Popover,
//   PopoverContent,
//   PrefixContext,
//   PrimaryButton,
//   ProgressBar,
//   ProgressIndicator,
//   ProgressIndicatorSkeleton,
//   ProgressStep,
//   RadioButton,
//   RadioButtonGroup,
//   RadioButtonSkeleton,
//   RadioTile,
//   Row,
//   Search,
//   SearchSkeleton,
//   SecondaryButton,
//   Section,
//   Select,
//   SelectItem,
//   SelectItemGroup,
//   SelectSkeleton,
//   SelectableTile,
//   SideNav,
//   SideNavDetails,
//   SideNavDivider,
//   SideNavFooter,
//   SideNavHeader,
//   SideNavIcon,
//   SideNavItem,
//   SideNavItems,
//   SideNavLink,
//   SideNavLinkText,
//   SideNavMenu,
//   SideNavMenuItem,
//   SideNavSwitcher,
//   SkeletonIcon,
//   SkeletonPlaceholder,
//   SkeletonText,
//   SkipToContent,
//   Slider,
//   SliderSkeleton,
//   Stack,
//   StructuredListBody,
//   StructuredListCell,
//   StructuredListHead,
//   StructuredListInput,
//   StructuredListRow,
//   StructuredListSkeleton,
//   StructuredListWrapper,
//   Switch,
//   Switcher,
//   SwitcherDivider,
//   SwitcherItem,
//   Tab,
//   TabContent,
//   TabList,
//   TabPanel,
//   TabPanels,
//   Table,
//   TableActionList,
//   TableBatchAction,
//   TableBatchActions,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableExpandHeader,
//   TableExpandRow,
//   TableExpandedRow,
//   TableHead,
//   TableHeader,
//   TableRow,
//   TableSelectAll,
//   TableSelectRow,
//   TableToolbar,
//   TableToolbarAction,
//   TableToolbarContent,
//   TableToolbarMenu,
//   TableToolbarSearch,
//   Tabs,
//   TabsSkeleton,
//   Tag,
//   TagSkeleton,
//   TextArea,
//   TextAreaSkeleton,
//   TextInput,
//   TextInputSkeleton,
//   Theme,
//   ThemeContext,
//   Tile,
//   TileAboveTheFoldContent,
//   TileBelowTheFoldContent,
//   TileGroup,
//   TimePicker,
//   TimePickerSelect,
//   ToastNotification,
//   Toggle,
//   ToggleSkeleton,
//   ToggleSmallSkeleton,
//   Toggletip,
//   ToggletipActions,
//   ToggletipButton,
//   ToggletipContent,
//   ToggletipLabel,
//   Tooltip,
//   TreeNode,
//   TreeView,
//   UnorderedList,
//   VStack,
//   types,
//   unstable_FeatureFlags,
//   unstable_Layout,
//   unstable_LayoutDirection,
//   unstable_OverflowMenuV2,
//   unstable_PageSelector,
//   unstable_Pagination,
//   unstable_Text,
//   unstable_TextDirection,
//   unstable__FluidComboBox,
//   unstable__FluidComboBoxSkeleton,
//   unstable__FluidDatePicker,
//   unstable__FluidDatePickerInput,
//   unstable__FluidDatePickerSkeleton,
//   unstable__FluidDropdown,
//   unstable__FluidDropdownSkeleton,
//   unstable__FluidMultiSelect,
//   unstable__FluidMultiSelectSkeleton,
//   unstable__FluidSelect,
//   unstable__FluidSelectSkeleton,
//   unstable__FluidTextArea,
//   unstable__FluidTextAreaSkeleton,
//   unstable__FluidTextInput,
//   unstable__FluidTextInputSkeleton,
//   unstable__FluidTimePicker,
//   unstable__FluidTimePickerSelect,
//   unstable__FluidTimePickerSkeleton,
//   unstable_useFeatureFlag,
//   unstable_useFeatureFlags,
//   unstable_useLayoutDirection,
//   useContextMenu,
//   useIdPrefix,
//   useLayer,
//   usePrefix,
//   useTheme,
// } from "@carbon/react";

export default function WireframesWrapper({ htmlString, title }) {
  return (
    <div className="wireframe-wrapper ">
      <Typography.Title level={2} className="text-header">
        {title || "SCREEN"}
      </Typography.Title>
      <div class="mobile-phone">
        <div class="brove">
          <span class="speaker"></span>
        </div>
        <div class="screen">
          <div
            dangerouslySetInnerHTML={{
              __html: `${htmlString
                ?.replaceAll(/className/g, "class")
                ?.replaceAll(/classname/g, "class")}`,
            }}
          ></div>
          {/* commenting for future use */}
          {/* <JsxParser
            // components={{
            //   CarbonComponents,
            //   Accordion,
            //   AccordionItem,
            //   AccordionSkeleton,
            //   ActionableNotification,
            //   AspectRatio,
            //   Breadcrumb,
            //   BreadcrumbItem,
            //   BreadcrumbSkeleton,
            //   Button,
            //   ButtonKinds,
            //   ButtonSet,
            //   ButtonSizes,
            //   ButtonSkeleton,
            //   ButtonTooltipAlignments,
            //   ButtonTooltipPositions,
            //   Checkbox,
            //   CheckboxGroup,
            //   CheckboxSkeleton,
            //   ClassPrefix,
            //   ClickableTile,
            //   CodeSnippet,
            //   CodeSnippetSkeleton,
            //   Column,
            //   ColumnHang,
            //   ComboBox,
            //   ComboButton,
            //   ComposedModal,
            //   ContainedList,
            //   ContainedListItem,
            //   Content,
            //   ContentSwitcher,
            //   ControlledPasswordInput,
            //   Copy,
            //   CopyButton,
            //   DangerButton,
            //   DataTable,
            //   DataTableSkeleton,
            //   DatePicker,
            //   DatePickerInput,
            //   DatePickerSkeleton,
            //   DefinitionTooltip,
            //   Dropdown,
            //   DropdownSkeleton,
            //   ErrorBoundary,
            //   ErrorBoundaryContext,
            //   ExpandableSearch,
            //   ExpandableTile,
            //   FileUploader,
            //   FileUploaderButton,
            //   FileUploaderDropContainer,
            //   FileUploaderItem,
            //   FileUploaderSkeleton,
            //   Filename,
            //   FilterableMultiSelect,
            //   FlexGrid,
            //   FluidForm,
            //   Form,
            //   FormContext,
            //   FormGroup,
            //   FormItem,
            //   FormLabel,
            //   GlobalTheme,
            //   Grid,
            //   HStack,
            //   Header,
            //   HeaderContainer,
            //   HeaderGlobalAction,
            //   HeaderGlobalBar,
            //   HeaderMenu,
            //   HeaderMenuButton,
            //   HeaderMenuItem,
            //   HeaderName,
            //   HeaderNavigation,
            //   HeaderPanel,
            //   HeaderSideNavItems,
            //   Heading,
            //   IconButton,
            //   IconSkeleton,
            //   IconSwitch,
            //   IconTab,
            //   IdPrefix,
            //   InlineLoading,
            //   InlineNotification,
            //   Layer,
            //   Link,
            //   ListItem,
            //   Loading,
            //   Menu,
            //   MenuButton,
            //   MenuItem,
            //   MenuItemDivider,
            //   MenuItemGroup,
            //   MenuItemRadioGroup,
            //   MenuItemSelectable,
            //   Modal,
            //   ModalBody,
            //   ModalFooter,
            //   ModalHeader,
            //   ModalWrapper,
            //   MultiSelect,
            //   NotificationActionButton,
            //   NotificationButton,
            //   NumberInput,
            //   NumberInputSkeleton,
            //   OrderedList,
            //   OverflowMenu,
            //   OverflowMenuItem,
            //   Pagination,
            //   PaginationNav,
            //   PaginationSkeleton,
            //   PasswordInput,
            //   Popover,
            //   PopoverContent,
            //   PrefixContext,
            //   PrimaryButton,
            //   ProgressBar,
            //   ProgressIndicator,
            //   ProgressIndicatorSkeleton,
            //   ProgressStep,
            //   RadioButton,
            //   RadioButtonGroup,
            //   RadioButtonSkeleton,
            //   RadioTile,
            //   Row,
            //   Search,
            //   SearchSkeleton,
            //   SecondaryButton,
            //   Section,
            //   Select,
            //   SelectItem,
            //   SelectItemGroup,
            //   SelectSkeleton,
            //   SelectableTile,
            //   SideNav,
            //   SideNavDetails,
            //   SideNavDivider,
            //   SideNavFooter,
            //   SideNavHeader,
            //   SideNavIcon,
            //   SideNavItem,
            //   SideNavItems,
            //   SideNavLink,
            //   SideNavLinkText,
            //   SideNavMenu,
            //   SideNavMenuItem,
            //   SideNavSwitcher,
            //   SkeletonIcon,
            //   SkeletonPlaceholder,
            //   SkeletonText,
            //   SkipToContent,
            //   Slider,
            //   SliderSkeleton,
            //   Stack,
            //   StructuredListBody,
            //   StructuredListCell,
            //   StructuredListHead,
            //   StructuredListInput,
            //   StructuredListRow,
            //   StructuredListSkeleton,
            //   StructuredListWrapper,
            //   Switch,
            //   Switcher,
            //   SwitcherDivider,
            //   SwitcherItem,
            //   Tab,
            //   TabContent,
            //   TabList,
            //   TabPanel,
            //   TabPanels,
            //   Table,
            //   TableActionList,
            //   TableBatchAction,
            //   TableBatchActions,
            //   TableBody,
            //   TableCell,
            //   TableContainer,
            //   TableExpandHeader,
            //   TableExpandRow,
            //   TableExpandedRow,
            //   TableHead,
            //   TableHeader,
            //   TableRow,
            //   TableSelectAll,
            //   TableSelectRow,
            //   TableToolbar,
            //   TableToolbarAction,
            //   TableToolbarContent,
            //   TableToolbarMenu,
            //   TableToolbarSearch,
            //   Tabs,
            //   TabsSkeleton,
            //   Tag,
            //   TagSkeleton,
            //   TextArea,
            //   TextAreaSkeleton,
            //   TextInput,
            //   TextInputSkeleton,
            //   Theme,
            //   ThemeContext,
            //   Tile,
            //   TileAboveTheFoldContent,
            //   TileBelowTheFoldContent,
            //   TileGroup,
            //   TimePicker,
            //   TimePickerSelect,
            //   ToastNotification,
            //   Toggle,
            //   ToggleSkeleton,
            //   ToggleSmallSkeleton,
            //   Toggletip,
            //   ToggletipActions,
            //   ToggletipButton,
            //   ToggletipContent,
            //   ToggletipLabel,
            //   Tooltip,
            //   TreeNode,
            //   TreeView,
            //   UnorderedList,
            //   VStack,
            //   types,
            //   unstable_FeatureFlags,
            //   unstable_Layout,
            //   unstable_LayoutDirection,
            //   unstable_OverflowMenuV2,
            //   unstable_PageSelector,
            //   unstable_Pagination,
            //   unstable_Text,
            //   unstable_TextDirection,
            //   unstable__FluidComboBox,
            //   unstable__FluidComboBoxSkeleton,
            //   unstable__FluidDatePicker,
            //   unstable__FluidDatePickerInput,
            //   unstable__FluidDatePickerSkeleton,
            //   unstable__FluidDropdown,
            //   unstable__FluidDropdownSkeleton,
            //   unstable__FluidMultiSelect,
            //   unstable__FluidMultiSelectSkeleton,
            //   unstable__FluidSelect,
            //   unstable__FluidSelectSkeleton,
            //   unstable__FluidTextArea,
            //   unstable__FluidTextAreaSkeleton,
            //   unstable__FluidTextInput,
            //   unstable__FluidTextInputSkeleton,
            //   unstable__FluidTimePicker,
            //   unstable__FluidTimePickerSelect,
            //   unstable__FluidTimePickerSkeleton,
            //   unstable_useFeatureFlag,
            //   unstable_useFeatureFlags,
            //   unstable_useLayoutDirection,
            //   useContextMenu,
            //   useIdPrefix,
            //   useLayer,
            //   usePrefix,
            //   useTheme,
            // }}
            
            jsx={`${htmlString}`}
          /> */}
        </div>
      </div>
    </div>
  );
}
