import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ROUTES } from "./common/constants";
import history from "./historyData";
import Login from "./auth/Login";
import AuthWrapper from "./auth/AuthWrapper";
import Templates from "./modules/projects/Dashboard";
import Error404 from "./common/Error404";
import TemplateDetails from "./modules/template/TemplateDetails";
// import BRD from "./modules/brd/BRD";
// import PRD from "./modules/prd/PRD";
import Projects from "./modules/projects/Projects";
import VerifyUser from "./auth/VerifyUser";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={ROUTES?.LOGIN} exact>
          <Login />
        </Route>
        <Route path={ROUTES?.HOME} exact>
          <Login />
        </Route>
        <AuthWrapper
          path={ROUTES?.DASHBOARD}
          exact
          component={
            <VerifyUser>
              <Templates />
            </VerifyUser>
          }
        />
        <AuthWrapper
          path={ROUTES?.PROJECTS}
          exact
          component={
            <VerifyUser>
              <Projects />
            </VerifyUser>
          }
        />
        <AuthWrapper
          path={ROUTES?.TEMPLATE_DETAILS}
          exact
          component={
            <VerifyUser>
              <TemplateDetails />
            </VerifyUser>
          }
        />
        {/* <AuthWrapper path={ROUTES?.BRD} exact component={<BRD />} />
        <AuthWrapper path={ROUTES?.PRD} exact component={<PRD />} /> */}
        <Route path={ROUTES?.EMPTY}>
          <Error404 />
        </Route>
      </Switch>
    </Router>
  );
};
export default Routes;
