import React, { useEffect, useMemo, useState } from "react";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/antd";
import "./index.scss";
import { updateData } from "../../firebase/api";
import { projectsRef } from "../../firebase/collection";
import { Button, Empty, Modal, Space, Spin, Typography, message } from "antd";
// import { MESSAGE } from "../../common/constants";
import { stripHTML } from "../../common/functions";
import { MESSAGE } from "../../common/constants";
import axios from "axios";

export default function RenderForm(props) {
  const {
    formSchema,
    uiSchema,
    project_id,
    formData,
    setKey,
    generateBRD,
    brd_content,
    is_dynamic,
    question_url,
    question_generated,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(question_generated);

  const [currentFormSchema, setCurrentFormSchema] = useState(
    JSON?.parse(stripHTML(formSchema))
  );
  const [currentFormData, setCurrentFormData] = useState(null);

  const handleUpdate = async ({ formData }) => {
    if (is_dynamic && !isFetched) {
      setLoading(true);
      axios
        ?.post(
          question_url,
          {
            payload: formData,
            token: process.env.REACT_APP_API_TOKEN,
          },
          {
            headers: {
              Authorization: process.env.REACT_APP_AUTH_KEY,
              "Content-Type": "application/json",
            },
          }
        )
        ?.then(async ({ data: { data } }) => {
          if (data) {
            const questionList = Object?.entries(data)?.map((item) => {
              return {
                [item?.[1]]: {
                  type: "string",
                  title: item?.[1],
                },
              };
            });
            const wrappedObject = {
              ...questionList?.reduce((acc, obj) => ({ ...acc, ...obj }), {}),
            };
            const newFormSchema = {
              ...currentFormSchema,
              properties: {
                ...currentFormSchema?.properties,
                ...wrappedObject,
              },
            };
            const oldFormSchema = {
              ...currentFormSchema,
              properties: {
                ...wrappedObject,
              },
            };
            setCurrentFormSchema(oldFormSchema);
            setCurrentFormData(formData);
            setLoading(false);
            await updateData(projectsRef, "projects", project_id, {
              json_schema: JSON?.stringify(newFormSchema),
              form_data: JSON?.stringify(formData),
              question_generated: true,
            });
            setIsFetched(true);
            document
              ?.getElementById("main-form")
              ?.scrollIntoView({ behavior: "smooth" });
          }
        })
        ?.catch((e) => {
          console?.error("Error generating questions =>", e);
          setLoading(false);
        });
    } else {
      setLoading(true);
      const payLoad = { ...formData, ...currentFormData };
      let isAdded = await updateData(projectsRef, "projects", project_id, {
        form_data: JSON?.stringify(payLoad),
        status: "PROCESSING",
      });

      if (isAdded) {
        setLoading(false);
        setKey("2");
        await generateBRD(payLoad);
      }
    }
  };

  const handleEdit = () => {
    setIsDisabled(false);
  };

  function transformErrors(errors) {
    return errors?.map((error) => {
      error.message = MESSAGE?.REQUIRED;
      return error;
    });
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const BUTTON_CLASS = document?.getElementsByClassName("custom-info-button");
    if (BUTTON_CLASS) {
      // Create a button element
      var button = document?.createElement("button");
      button.type = "button";
      button.innerHTML = `<svg class="info-svg" viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="20" height="20" fill="currentColor" aria-hidden="true">
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path></svg>`;

      // Add a click event listener to the button
      const handleButtonClick = () => {
        setIsModalOpen(true);
      };

      button?.addEventListener("click", handleButtonClick);
      BUTTON_CLASS?.[0]?.appendChild(button);
    }
  }, []);

  const renderForm = useMemo(() => {
    if (formSchema) {
      return (
        <Form
          validator={validator}
          schema={currentFormSchema}
          uiSchema={JSON.parse(stripHTML(uiSchema))}
          formData={formData ? JSON.parse(formData) : null}
          onSubmit={(data) => handleUpdate(data)}
          disabled={isDisabled}
          transformErrors={transformErrors}
          className="form-wrapper"
          id="main-form"
          // onError={}
        >
          <div className="footer-buttons">
            <Space>
              {formData && <Button onClick={handleEdit}>Edit</Button>}
              <Button type="primary" htmlType="submit">
                {brd_content
                  ? is_dynamic && !isFetched
                    ? "Regenerate Questions"
                    : "Regenerate BRD"
                  : is_dynamic && !isFetched
                  ? "Generate Questions"
                  : "Generate BRD"}
              </Button>
            </Space>
          </div>
        </Form>
      );
    }
    return <Empty />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, currentFormSchema, isFetched]);

  useEffect(() => {
    if (props?.formData) {
      setIsDisabled(true);
    }
  }, [props]);
  return (
    <div className="template-form">
      <Spin spinning={loading}>{renderForm}</Spin>
      <Modal
        title="Info"
        width={600}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="description-modal"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Typography.Title level={5} className="modal-header">
          Here's a sample response to help you understand how to answer. Please
          provide your unique ideas and information.
        </Typography.Title>

        <p className="text-paragraph">
          We want to build an ultimate task management solution, to simplify
          users' lives and boost productivity. In a fast-paced world where
          managing multiple tasks can be a significant challenge, we aim to help
          users regain control and focus on what matters most. The challenge
          we're addressing is the increasing complexity of modern life, where
          tasks and responsibilities pile up, often leading to stress and
          inefficiency.
        </p>

        <p className="text-paragraph">
          Our application offers a user-friendly interface for creating to-do
          lists, setting deadlines, categorizing tasks, and prioritizing them
          intuitively. We aim to make a day more efficient and organized,
          allowing users to manage and track tasks in one place. Our app caters
          to a wide user base, from professionals and students to busy parents
          and freelancers, offering a versatile tool for anyone looking to
          streamline their daily responsibilities.
        </p>

        <p className="text-paragraph">
          We stand out in a competitive landscape, with features such as smart
          task sorting, easy-to-use deadlines, collaborative task lists, and
          progress tracking. Notable competitors in this space include apps like
          Todoist, Trello, and Microsoft To Do, but our application provides a
          unique blend of functionality and simplicity that sets it apart. Our
          aim is to empower users to master their tasks and maintain control
          over their daily agenda, effectively addressing the challenge of
          modern task management.
        </p>
      </Modal>
    </div>
  );
}
