
import Routes from "../Routes";
import "antd/dist/antd.less";
import "./App.scss";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
