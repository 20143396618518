import React, { createContext, useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/config.js";
const initialState = {
  // eslint-disable-next-line no-undef
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return { ...state, user: action.data };
    case "SET_USER_DATA":
      return { ...state, userData: action.data };
    case "SET_AUTH":
      return { ...state, isAuthenticated: true };
    case "TOGGLE_BRD_BUTTON":
      return { ...state, isBRD: action?.data?.isBRD };
    case "TOGGLE_PRD_BUTTON":
      return { ...state, isPRD: action?.data?.isPRD };
    case "TOGGLE_FLOW_BUTTON":
      return { ...state, isFlows: action?.data?.isFlows };
    case "TOGGLE_WIREFRAME_BUTTON":
      return { ...state, isFrames: action?.data?.isFrames };

    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user] = useAuthState(auth);
  useEffect(() => {
    if (user) {
      dispatch({ type: "SET_CURRENT_USER", data: user });
    }
  }, [user]);
  const value = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
