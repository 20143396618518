// import { NodeViewWrapper } from "@tiptap/react";
// import { Button } from "antd";
// import React from "react";

export default function Component(props) {
  // const { type } = props.node.attrs;
  // const increase = () => {
  //     props.updateAttributes({
  //       count: props.node.attrs.count + 1,
  //     })
  //   }
  return (
    "Add block"
    // <NodeViewWrapper className="custom-button">
    //   {/* {level === 1 && <h1>{content}</h1>}
    //   {level === 2 && <h2>{content}</h2>}
    //   {level === 3 && <h3>{content}</h3>}
    //   {level === 4 && <h4>{content}</h4>}
    //   {level === 5 && <h5>{content}</h5>}
    //   {level === 6 && <h6>{content}</h6>} */}
      
    // </NodeViewWrapper>
  );
}
