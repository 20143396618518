import "./index.scss";
import React, { useContext, useEffect, useState } from "react";
import {
  addData,
  fetchData,
  fetchDataWithWhere,
} from "../../firebase/api.js";
import {
  projectsRef,
  templateRef,
  usersRef,
} from "../../firebase/collection.js";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext.js";
import { MESSAGE } from "../../common/constants.js";
import { where } from "firebase/firestore";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Text, Title } = Typography;
export default function Dashboard() {
  const [templateList, setTemplateList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [assignedProjectList, setAssignedProjectList] = useState([]);
  const [allProjectList, setAllProjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  const columns = [
    {
      title: "Title",
      dataIndex: "project_name",
      key: "project_name",
      render: (text, { id }) => {
        return (
          <Link to={`/projects/${id}`}>
            <Text strong underline>
              {text}
            </Text>
          </Link>
        );
      },
    },
    {
      title: "Template",
      dataIndex: "template_name",
      key: "template_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "PENDING") {
          return <Tag color="error">PENDING</Tag>;
        } else if (status === "PROCESSING") {
          return <Tag color="processing">PROCESSING</Tag>;
        } else if (status === "PROCESSED") {
          return <Tag color="success">PROCESSED</Tag>;
        }
      },
    },
  ];
  const assignedColumns = [
    {
      title: "Title",
      dataIndex: "project_name",
      key: "project_name",
      render: (text, { id }) => {
        return (
          <Link to={`/projects/${id}`}>
            <Text strong underline>
              {text}
            </Text>
          </Link>
        );
      },
    },
    {
      title: "Template",
      dataIndex: "template_name",
      key: "template_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "PENDING") {
          return <Tag color="error">PENDING</Tag>;
        } else if (status === "PROCESSING") {
          return <Tag color="processing">PROCESSING</Tag>;
        } else if (status === "PROCESSED") {
          return <Tag color="success">PROCESSED</Tag>;
        }
      },
    },
  ];

  const fetchUserProjects = async () => {
    const id = state?.user?.uid;
    let data = await fetchDataWithWhere(
      projectsRef,
      id,
      where("uid", "==", id)
    );
    setProjectList(data?.filter(item => item?.is_active===true));
    setLoading(false);
  };

  const fetchAllProjects = async () => {
    const data = await fetchData(projectsRef);
    setAllProjectList(data?.filter(item => item?.is_active===true));
  };

  const fetchUserAssignedProjects = async () => {
    const id = state?.user?.uid;
    const data = await fetchDataWithWhere(usersRef, id, where("uid", "==", id));
    const userProjectList = data?.[0]?.projects_assigned;
    if (userProjectList?.length > 0) {
      const updatedArray = userProjectList?.map((item) => {
        const project = allProjectList?.filter(
          (value) => value?.id === item
        )?.[0];
        return {
          id: item,
          project_name: project?.project_name,
          template_name: project?.template_name,
          status: project?.status,
          is_active: project?.is_active,
        };
      });
      setAssignedProjectList(updatedArray?.filter(item=>item?.is_active));
    }
    setLoading(false);
  };

  const fetchAllTemplates = async () => {
    let data = await fetchData(templateRef);
    setTemplateList(data?.filter((d) => d.is_active === true));
    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCreateButton = () => {
    setIsModalOpen(true);
  };

  const onFinish = async (value) => {
    setIsModalOpen(false);
    setLoading(true);
    const template = templateList?.filter(
      (d) => d?.id === value?.template_id
    )[0];

    const template_name = template?.name;
    const brd_url = template?.brd_url || "";
    const prd_url = template?.prd_url || "";
    const flow_url = template?.flow_url || "";
    const wireframe_url = template?.wireframe_url || false;
    const is_active = template?.is_active || "";
    const json_schema = template?.json_schema || "";
    const ui_schema = template?.ui_schema || "";
    const brd_template_id = template?.brd_template_id || "";
    const prd_template_id = template?.prd_template_id || "";
    const flow_template_id = template?.flow_template_id || "";
    const wireframe_template_id = template?.wireframe_template_id || "";
    const status = "PENDING";
    const is_dynamic = template?.is_dynamic || false;
    const question_url = template?.question_url || "";

    let project_id = await addData(projectsRef, {
      ...value,
      uid: state?.user?.uid,
      email: state?.user?.email,
      name: state?.user?.displayName,
      template_name,
      brd_url,
      prd_url,
      flow_url,
      wireframe_url,
      is_active,
      json_schema,
      ui_schema,
      status,
      brd_template_id,
      prd_template_id,
      flow_template_id,
      wireframe_template_id,
      is_dynamic,
      question_url
    });

    if (project_id) {
      message.success({ content: MESSAGE?.ADD });
      history?.push(`/projects/${project_id}`);
      setLoading(false);
      // fetchUserProjects();
      // handleCancel();
      // history?.push("/brd");
    }
  };

  useEffect(() => {
    if (state?.user) {
      fetchUserProjects();
      fetchAllTemplates();
      fetchAllProjects();
      dispatch({ type: "TOGGLE_BRD_BUTTON", data: { isBRD: false } });
      dispatch({ type: "TOGGLE_PRD_BUTTON", data: { isPRD: false } });
      dispatch({ type: "TOGGLE_FLOW_BUTTON", data: { isFlows: false } });
      dispatch({ type: "TOGGLE_WIREFRAME_BUTTON", data: { isFrames: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user]);

  const handleSwitch = (toggle) => {
    if (toggle) {
      fetchUserAssignedProjects();
    } else {
      setAssignedProjectList([]);
    }
  };

  return (
    <div className="container">
      <div className="dashboard-wrapper">
        <div className="header-wrapper">
          <Title level={2} className="text-project">
            Projects
          </Title>
          <Space>
            <Typography.Text>View Shared Projects</Typography.Text>
            <Switch onChange={handleSwitch} />
            <Button
              type="primary"
              onClick={handleCreateButton}
              icon={<PlusOutlined />}
            >
              New Project
            </Button>
          </Space>
        </div>
        <Table columns={columns} dataSource={projectList} loading={loading} />
        {assignedProjectList?.length > 0 && (
          <Table
            columns={assignedColumns}
            dataSource={assignedProjectList}
            loading={loading}
          />
        )}
        <Modal
          title="Create a New Project"
          open={isModalOpen}
          footer={false}
          onCancel={handleCancel}
        >
          <Form
            className="add-project-modal"
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
            autoComplete="off"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="project_name"
              label="Project Title"
              rules={[
                {
                  required: true,
                  message: "Title required",
                },
              ]}
            >
              <Input placeholder="Enter Project Title" />
            </Form.Item>
            <Form.Item
              name="template_id"
              label="Select a Template"
              rules={[
                {
                  required: true,
                  message: "Template required",
                },
              ]}
            >
              <Select placeholder="Select template" showSearch allowClear>
                {templateList &&
                  templateList?.map(({ name, id }) => (
                    <Select.Option value={id}>{name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="submit-footer">
              <Space>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
