import { signOut } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { ROUTES, TOKEN } from "./constants";
import { auth } from "../firebase/config.js";
import { useHistory, useLocation } from "react-router-dom";
import { Avatar, Button, Divider, Dropdown, Typography } from "antd";
import "./index.scss";
import { AppContext } from "../AppContext";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [current, setCurrent] = useState(location?.pathname);
  const { state } = useContext(AppContext);
  const history = useHistory();
  // const { id } = useParams();

  useEffect(() => {
    setCurrent(location?.pathname);
  }, [location]);
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage?.removeItem(TOKEN);
      history?.replace(ROUTES?.LOGIN);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const items = [
    {
      label: (
        <Button icon={<LogoutOutlined />} onClick={handleLogout}>
          Logout
        </Button>
      ),
      key: "logout",
    },
  ];

  /*Export List Dropdown */
  const exportList = [
    {
      label: "BRD",
      key: "1",
      disabled: !state?.isBRD || false,
    },
    {
      label: "PRD",
      key: "2",
      disabled: !state?.isPRD || false,
    },
    {
      label: "User Flows",
      key: "3",
      disabled: !state?.isFlows || false,
    },
  ];

  // commenting for future use
  // const handleMenuClick = (item) => {
  // };

  const menuProps = {
    items: exportList,
    // onClick: handleMenuClick,
  };

  // const onClick = ({ key }) => {
  //   setCurrent(key);
  //   history?.push(key);
  // };
  return (
    <header className="container">
      <div className="header-wrapper">
        <div className="menu-wrapper">
          <Typography.Paragraph className="text-logo">
            <Link to={ROUTES?.DASHBOARD}> I2D</Link>
          </Typography.Paragraph>
          {/* <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          /> */}
        </div>
        <div className="profile-items">
          {/* commenting for future use */}
          {/* {current?.includes("projects") && (
            <Dropdown.Button menu={menuProps}>Export</Dropdown.Button>
          )} */}
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottom"
            arrow
          >
            {state?.user?.photoURL ? (
              <Avatar src={state?.user?.photoURL} size="large"></Avatar>
            ) : (
              <Button shape="round" size="large">
                {state?.user?.displayName}
              </Button>
            )}
          </Dropdown>
          <Typography.Text className="text-profile" strong>
            {state?.user?.displayName}
          </Typography.Text>
        </div>
      </div>
      <Divider />
    </header>
  );
}
